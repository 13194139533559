import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeAndFormat'
})
export class CapitalizeAndFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    
    return value
      .split('_') // Split by underscore
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' '); // Join the words with spaces
  }
}