import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { EcoJsonEditorComponent } from './eco-json-editor.component';

@NgModule({
  declarations: [EcoJsonEditorComponent],
  imports: [CommonModule, NgJsonEditorModule],
  exports: [EcoJsonEditorComponent],
})
export class EcoJsonEditorModule {}
