import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { Message } from 'app/layout/common/messages/messages.types';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { WebsocketService, AdminService, LocalStorageService } from '@services';
import moment from 'moment-timezone';

@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  exportAs: 'messages',
})
export class MessagesComponent implements OnInit, OnDestroy {
  @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
  @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

  messages: Message[] = [];
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _messagesService: MessagesService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private locaStorageService: LocalStorageService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getAll();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  // Open the messages panel and load alerts
  openPanel(): void {
    // Load the latest alerts when the panel is opened
    this.getAll();

    // Return if the messages panel or its origin is not defined
    if (!this._messagesPanel || !this._messagesOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this._messagesPanel, this._viewContainerRef)
    );
  }

  /**
   * Close the messages panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Mark all messages as read
   */
  markAllAsRead(): void {
    this._messagesService.markAllAsRead().subscribe();
  }

  /**
   * Toggle read status of the given message
   */
  toggleRead(message: Message): void {
    message.read = !message.read;
    this._messagesService.update(message.id, message).subscribe();
  }

  /**
   * Delete the given message
   */
  delete(message: Message): void {
    this._messagesService.delete(message.id).subscribe();
  }

  /**
   * Track by function for ngFor loops
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  /**
   * Get formatted date for display
   */
  private getFormattedDate(dateTime: string): string {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userTimeZone = this.locaStorageService.userTimezone || localTimezone;
    const dateInTimezone = moment(dateTime).tz(userTimeZone);
    const today = moment().tz(userTimeZone);
    const yesterday = moment().tz(userTimeZone).subtract(1, 'days');

    if (dateInTimezone.isSame(today, 'day')) {
      return `Today, ${dateInTimezone.format('h:mm a')}`;
    } else if (dateInTimezone.isSame(yesterday, 'day')) {
      return `Yesterday, ${dateInTimezone.format('h:mm a')}`;
    } else {
      return dateInTimezone.format('MMM DD, h:mm a');
    }
  }

  private getTimeDifference(dateTime: string): { difference: string; badgeClass: string } {
    const currentTime = moment();
    const messageTime = moment(dateTime);
    const duration = moment.duration(currentTime.diff(messageTime));
    
    const hours = duration.hours() + duration.days() * 24; // Convert days to hours
    const minutes = duration.minutes();

    let badgeClass = '';

    // Determine badge class based on time difference
    if (duration.asMinutes() < 15) {
      badgeClass = 'badge-success'; // Green badge
    } else if (duration.asMinutes() < 60) {
      badgeClass = 'badge-warning'; // Warning badge
    } else {
      badgeClass = 'badge-error'; // Error badge
    }

    // Check if the difference is more than 12 hours
    if (hours > 12) {
      return { difference: '12 hours +', badgeClass };
    }

    // Display formatting based on hour and minute values
    if (hours > 0) {
      return { difference: `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''} ago`, badgeClass };
    } else {
      return { difference: `${minutes} minute${minutes !== 1 ? 's' : ''} ago`, badgeClass };
    }
  }

  private getAll(): void {
    const paramsObj = {
      size: 1000,
      page: 0,
      order: 'desc',
      sort: 'timestamp',
    };

    this._messagesService.getAlerts(paramsObj).subscribe((res: any) => {
      const result_info = res.result_info;
      this.messages = res.data.map((elem: any) => {
        const { difference, badgeClass } = this.getTimeDifference(elem.timestamp);
        return {
          ...elem,
          formattedDate: this.getFormattedDate(elem.timestamp),
          timeDifference: difference,
          badgeClass, // Add badgeClass to message object
        };
      });
      this.unreadCount = result_info.total_records;
    });
  }

  private establishConnections() {
    // this.socketSubscription = this.websocketService
    //   .connect('wss://dev1.api.ecommify.io/v2/admin/alerts')
    //   .subscribe(
    //     (res: any) => {
    //       const result_info = res.result_info;
    //       this.messages = res.data;
    //       this.unreadCount = result_info.total_records;
    //     },
    //     error => {
    //       console.log('Socket Error');
    //     }
    //   );
  }
}
