import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

export interface ColumnDefinition {
  name: string;
  label: string;
  type: 'clickableId' | 'text' | 'platformWithIcon' | 'statusBadge' | 'date';
  field: string;
  iconField?: string;
  defaultDate?: Date;
}

export interface TabOption {
  name: string;
  status: string | boolean;
}

export interface ActionItem {
  label: string;
  action: string;
}

@Component({
  selector: 'eco-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  @Input() title: string = '';
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource<any>(
    []
  );
  @Input() tabOptions: TabOption[] = [];
  @Input() selectedTab: TabOption = { name: 'All', status: 'all' };
  @Input() columns: ColumnDefinition[] = [];
  @Input() actions: ActionItem[] = [];
  @Input() secondaryActions: ActionItem[] = [];
  @Input() addButtonText: string = 'Add Item';
  @Input() selection = new SelectionModel<any>(true, []);
  @Input() isAllSelected: boolean;
  @Input() noDataMessage: string = 'There are no items to display at this time';

  @Output() tabChange = new EventEmitter<TabOption>();
  @Output() rowDrop = new EventEmitter<CdkDragDrop<any[]>>();
  @Output() addClick = new EventEmitter<void>();
  @Output() rowSelect = new EventEmitter<{ event: any; element: any }>();
  @Output() rowAction = new EventEmitter<{ element: any; action: string }>();
  @Output() toggleAll = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<SelectionModel<any>>();

  displayedColumns: string[] = [];

  // Getter for isAllSelected to use in template if not provided as input
  get allSelectedState(): boolean {
    return this.isAllSelected ?? this._isAllSelected();
  }

  ngOnInit(): void {
    // Build displayed columns from the columns input plus select and action
    this.displayedColumns = [
      'select',
      ...this.columns.map(col => col.name),
      'action',
    ];
  }

  onTabClick(tab: TabOption): void {
    this.selectedTab = tab;
    this.tabChange.emit(tab);
  }

  onTableRowDrop(event: CdkDragDrop<any[]>): void {
    this.rowDrop.emit(event);
  }

  onAddClick(): void {
    this.addClick.emit();
  }

  onRowAction(element: any, action: string): void {
    this.rowAction.emit({ element, action });
  }

  // Toggles a single row selection
  toggleRowSelection(element: any): void {
    this.selection.toggle(element);
    this.selectionChange.emit(this.selection);
    this.rowSelect.emit({ event: null, element });
  }

  toggleAllRows(): void {
    if (this._isAllSelected()) {
      this.selection.clear();
    } else {
      this.selection.select(...this.dataSource.data);
    }
    this.selectionChange.emit(this.selection);
  }

  // Private method to determine if all rows are selected
  private _isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows && numRows > 0;
  }

  // Helper method to access nested properties using dot notation
  getNestedProperty(obj: any, path: string): any {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj);
  }
}
