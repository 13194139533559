<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark print:hidden"
  [appearance]="navigationAppearance"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center justify-center h-20">
      <img
        class="w-10"
        src="assets/eco-images/eco-white-logo.svg"
        alt="Logo image"
        style="margin-top: -14px" />
    </div>
  </ng-container>

  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationFooter>
    <!-- User -->
    <div class="flex items-center w-full px-6 py-8">
      <!--<user></user>-->
      <div class="flex flex-col w-full ml-4 overflow-hidden">
        <!--<div
          class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal text-current opacity-80">
          {{ companyName }}
        </div>-->
        <div
          class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis overflow-hidden leading-normal text-current opacity-50">
          Company ID
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis overflow-hidden leading-normal text-current opacity-50">
          {{ companyId }}
        </div>
      </div>
    </div>
  </ng-container>

</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <div class="flex items-center pr-2 space-x-2">
      <!-- Navigation toggle button -->
      <!-- <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button> -->
      <button mat-icon-button (click)="toggleNavigationAppearance()">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
      <!-- <div>
        <img
          class="w-8 mt-16px"
          src="assets/eco-images/eco-full-logo.svg"
          alt="Logo image"
          style="width: 180px !important" />
      </div> -->
      <!-- Navigation appearance toggle button -->
      <!-- <button
        class="hidden md:inline-flex"
        mat-icon-button
        (click)="toggleNavigationAppearance()">
        <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
      </button> -->
    </div>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <!-- <div *ngIf="showSettings()" routerLink="settings">
        <button mat-icon-button>
          <mat-icon
            [svgIcon]="'heroicons_outline:cog'"
            [matTooltip]="'Settings'"></mat-icon>
        </button>
      </div> -->
      <!-- <languages></languages> -->
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <!-- <search [appearance]="'bar'"></search> -->
      <!-- <shortcuts></shortcuts> -->
      <messages></messages>
      <!-- <notifications></notifications> -->
      <!-- <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
        <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
      </button> -->
      <!-- <user></user> -->
      <ng-container fuseVerticalNavigationFooter>
        <!-- User -->
        <user></user>
        <div
          class="flex items-center w-full px-0 py-8 border-t hidden sm:block">
          <div class="flex flex-col w-[160px] ml-1 overflow-hidden">
            <div
              *ngIf="showCompany()"
              class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal text-current text-sm">
              {{ companyName }}
            </div>
            <div
              *ngIf="showSwitchCompany()"
              class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal text-current text-sm">
              <mat-select
                [formControl]="companyControl"
                class="companyList"
                (selectionChange)="selectedCompany($event)">
                <mat-option disabled *ngIf="showFilter">
                  <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:search'"></mat-icon>
                  <input
                    class="min-w-0 ml-2 py-1 border-0"
                    type="text"
                    matInput
                    [formControl]="filterText"
                    placeholder="Search Company" />
                </mat-option>
                <mat-divider></mat-divider>

                <ng-container *ngIf="filteredOptions | async as company">
                  <mat-option disabled *ngIf="company.length === 0"
                    >No Companies Found</mat-option
                  >
                  <mat-option
                    *ngFor="let opt of companies; trackBy: trackByFn"
                    [value]="opt.company_id"
                    [ngStyle]="getOptionStyle(opt, company)"
                    >{{ opt.company_name }}</mat-option
                  >
                </ng-container>
              </mat-select>
            </div>
            <div
              class="w-full whitespace-nowrap text-xs text-ellipsis overflow-hidden leading-normal text-current opacity-50">
              {{ user?.name }}
            </div>

            <!-- <div
                            class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis overflow-hidden leading-normal text-current opacity-50">
                            {{ user.email }}
                        </div> -->
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!-- <div
    class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary"
      >Fuse &copy; {{ currentYear }}</span
    >
  </div> -->
</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat" *ngIf="false"></quick-chat>
