import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductAttribute } from 'app/modules/user/sync-logs/products/products.types';
import { SyncLogsService } from 'app/modules/user/sync-logs/sync-logs.service';

@Component({
  selector: 'eco-bulk-edit-modal',
  templateUrl: './bulk-edit-modal.component.html',
  styleUrls: ['./bulk-edit-modal.component.scss'],
})
export class BulkEditModalComponent implements OnInit {
  activeTab: 'attribute' | 'category' | 'relationship' | 'findReplace' =
    'attribute';

  attributeList: ProductAttribute[] = [];
  form: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BulkEditModalComponent>,
    private _syncLogsService: SyncLogsService
  ) {}

  ngOnInit(): void {
    console.log('data', this.data);
    this.form = {
      filters: [
        [
          {
            field: 'id',
            operator: 'in',
            value: this.data.selected.map(product => product.id),
          },
        ],
      ],
    };

    this.attributeList = this.data?.attributeList || [];

    console.log('form', this.form);
  }

  closeDialog(data: any = null) {
    this.dialogRef.close(data);
  }

  submitAttributeForm(value: any) {
    console.log('attribute form submit : ', value, this.form);
    let selected_attributes_value = {};
    value.forEach(element => {
      selected_attributes_value[
        (element.attribute.attribute_type == 'user-defined'
          ? 'attributes.'
          : '') + element.attribute.code
      ] = element.value;
    });

    let data = {
      filters: [...this.form.filters],
      attributes: {
        ...selected_attributes_value,
      },
    };
    console.log('data : ', data);

    this._syncLogsService.bulkUpdate(data).subscribe(res => {
      console.log('IN submitAttributeForm FN >>>>>>>', res);
      this.closeDialog();
    });
  }

  submitFindReplaceForm(value) {
    console.log('find & replace form submit : ', value);
    let selected_attributes_value = {};
    selected_attributes_value[
      (value.attribute.attribute_type == 'user-defined' ? 'attributes.' : '') +
        value.attribute.code
    ] = value.replaceQuery;

    this.form.filters[0].push({
      field: 'attributes.' + value.attribute.code,
      operator: 'eq',
      value: value.findQuery,
    });

    let data = {
      filters: [...this.form.filters],
      attributes: {
        ...selected_attributes_value,
      },
    };
    console.log('data : ', data);

    this._syncLogsService.bulkUpdate(data).subscribe(res => {
      console.log('IN submitFindReplaceForm FN >>>>>>>', res);
      this.closeDialog();
    });
  }

  submitRelationshipForm(bulkRelationship) {
    console.log(
      'relationship form submit : ',
      bulkRelationship.relationshipForm,
      bulkRelationship.selection.selected
    );
  }
}
