import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { PipesModule } from './pipes/pipes.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTreeModule } from '@angular/material/tree';
import { AttributeSelectorComponent } from './components/attribute-selector/attribute-selector.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProductAttributeGroupService } from '../services/product-attribute-group.service';
import { AttributeFilterComponent } from './filters/attribute-filter/attribute-filter.component';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MtxSelectModule } from '@ng-matero/extensions/select';
import { MtxNativeDatetimeModule } from '@ng-matero/extensions/core';
import { MatSelectModule } from '@angular/material/select';

import { CompanyTitleFilterComponent } from './filters/company-title-filter/company-title-filter.component';
import { DateFilterComponent } from './filters/date-filter/date-filter.component';
import { GroupFilterComponent } from './filters/group-filter/group-filter.component';
import { IntegrationTitleFilterComponent } from './filters/integration-title-filter/integration-title-filter.component';
import { OrderActionFilterComponent } from './filters/order-action-filter/order-action-filter.component';
import { OrderLifecycleFilterComponent } from './filters/order-lifecycle-filter/order-lifecycle-filter.component';
import { OrderStatusFilterComponent } from './filters/order-status-filter/order-status-filter.component';
import { OrderSyncStatusFilterComponent } from './filters/order-sync-status-filter/order-sync-status-filter.component';
import { RelationshipFilterComponent } from './filters/relationship-filter/relationship-filter.component';
import { SyncOptionFilterComponent } from './filters/sync-option-filter/sync-option-filter.component';
import { InstanceTitleFilterComponent } from './filters/instance-title-filter/instance-title-filter.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';

import { ProductCategoryFilterModule } from './filters/product-category-filter/product-category-filter.module';
import { DataTableComponent } from './components/data-table/data-table.component';
import { EcoJsonEditorModule } from './components/eco-json-editor/eco-json-editor.module';

import { BulkAttributesComponent } from './components/bulk-attributes/bulk-attributes.component';
import { BulkCategoriesComponent } from './components/bulk-categories/bulk-categories.component';
import { BulkEditModalComponent } from './components/bulk-edit-modal/bulk-edit-modal.component';
import { BulkFindReplaceComponent } from './components/bulk-find-replace/bulk-find-replace.component';
import { BulkRelationshipsComponent } from './components/bulk-relationships/bulk-relationships.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  PipesModule,
  PortalModule,
  FuseDrawerModule,
  MatSnackBarModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatExpansionModule,
  MatIconModule,
  MatCheckboxModule,
  DragDropModule,
  MtxDatetimepickerModule,
  MtxSelectModule,
  MtxNativeDatetimeModule,
  MatSelectModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSortModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatCardModule,
  MatTableModule,
  MatButtonToggleModule,
  MatSidenavModule,
  ClipboardModule,
  ProductCategoryFilterModule,
  MatTreeModule,
  EcoJsonEditorModule,
];

const components = [
  AttributeSelectorComponent,
  AttributeFilterComponent,
  CompanyTitleFilterComponent,
  DateFilterComponent,
  GroupFilterComponent,
  IntegrationTitleFilterComponent,
  OrderActionFilterComponent,
  OrderLifecycleFilterComponent,
  OrderStatusFilterComponent,
  OrderSyncStatusFilterComponent,
  RelationshipFilterComponent,
  SyncOptionFilterComponent,
  InstanceTitleFilterComponent,
  BulkAttributesComponent,
  BulkCategoriesComponent,
  BulkEditModalComponent,
  BulkFindReplaceComponent,
  BulkRelationshipsComponent,
  DataTableComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...modules, ...components, EcoJsonEditorModule],
  declarations: [...components],
  providers: [
    ProductAttributeGroupService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
})
export class SharedModule {}
