import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
  selector: 'eco-json-editor',
  templateUrl: './eco-json-editor.component.html',
  styleUrls: ['./eco-json-editor.component.scss'],
})
export class EcoJsonEditorComponent implements OnInit {
  @Input() jsonData: any = {};
  @Output() closeEditor: EventEmitter<null> = new EventEmitter<null>();
  @Output() saveSuccess: EventEmitter<any> = new EventEmitter<any>();

  public editorOptions: JsonEditorOptions;
  @ViewChild('jsonEditor', { static: false })
  editor: JsonEditorComponent;

  constructor() {
    // Initialize with basic options
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'tree'];
    this.editorOptions.mode = 'code';
    // Remove expandAll as it's causing issues
    // this.editorOptions.expandAll = true;
  }

  ngOnInit(): void {
    console.log('Editor initialized with data:', this.jsonData);
  }

  /**
   * Get the current JSON data from the editor
   */
  getData(): any {
    if (this.editor) {
      try {
        return this.editor.get();
      } catch (e) {
        return this.jsonData;
      }
    }
    return this.jsonData;
  }
}
