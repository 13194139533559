import { FuseNavigationItem } from '@fuse/components/navigation';
import { LocalStorageUtils } from '../common/local-storage.utils';

// super admin nav items

export const superAdminNavigationItems: FuseNavigationItem[] = [
  // 'aside' | 'basic' | 'collapsable' | 'divider' | 'group' | 'spacer';
  {
    id: 'admin.dashboards',
    title: 'Monitoring',
    type: 'collapsable',
    icon: 'heroicons_outline:presentation-chart-line',
    link: '/admin/dashboard',
    children: [
      /*
      {
        id: 'admin.integration-syncs',
        title: 'Integration Syncs',
        type: 'basic',
        // icon: 'heroicons_outline:user-group',
        link: '/admin/integration-syncs',
      },
      {
        id: 'admin.source-syncs',
        title: 'Source Syncs',
        type: 'basic',
        // icon: 'heroicons_outline:user-group',
        link: '/admin/source-syncs',
      },
      */
      {
        id: 'admin.dashboards1',
        title: 'Batch Job',
        type: 'basic',
        // icon: 'heroicons_outline:clipboard-list',
        link: '/admin/batch-job',
      },
      {
        id: 'sync-logs.integrationSyncOptions',
        title: 'Sync Options',
        type: 'basic',
        // icon: 'heroicons_outline:adjustments',
        link: '/admin/integration-syn-options',
      },
    ],
  },

  {
    id: 'admin.companies',
    title: 'Companies',
    type: 'basic',
    icon: 'heroicons_outline:library',
    link: '/admin/companies',
  },
  {
    id: 'admin.users',
    title: 'Users',
    type: 'basic',
    icon: 'heroicons_outline:user-group',
    link: '/admin/users',
  },
  {
    id: 'admin.integrations',
    title: 'Channels',
    type: 'basic',
    icon: 'heroicons_outline:chip',
    link: '/admin/integrations',
  },
  {
    id: 'admin.sources',
    title: 'Sources',
    type: 'basic',
    icon: 'heroicons_outline:globe',
    link: '/admin/sources',
  },
  {
    id: 'company.settings',
    title: 'Settings',
    type: 'basic',
    icon: 'heroicons_outline:cog',
    link: '/settings/product/attributes',
    hidden: item => (LocalStorageUtils.companyId ? false : true),
  },
];

//admin nav items

export const adminNavigationItems: FuseNavigationItem[] = [
  {
    id: 'admin.dashboards',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/admin/dashboard',
  },
  {
    id: 'admin.companies',
    title: 'Companies',
    type: 'basic',
    icon: 'heroicons_outline:library',
    link: '/admin/companies',
  },
  {
    id: 'admin.batchjob',
    title: 'Batch Job',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-list',
    link: '/admin/job-batches',
  },
  {
    id: 'admin.users',
    title: 'Users',
    type: 'basic',
    icon: 'heroicons_outline:user-group',
    link: '/admin/users',
  },
  {
    id: 'company.settings',
    title: 'Settings',
    type: 'basic',
    icon: 'heroicons_outline:cog',
    link: '/settings/product/attributes',
    hidden: item => (LocalStorageUtils.companyId ? false : true),
  },
];

// sub user nav items

export const userNavigationItems: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboard',
    type: 'collapsable',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard/channel-status',
    children: [
      {
        id: 'sync-logs.integrationStatus',
        title: 'Channel Status',
        type: 'basic',
        link: '/dashboard/channel-status',
        // icon: 'heroicons_outline:chip',
      },
      {
        id: 'sync-logs.integrationSyncOptions',
        title: 'Sync Options',
        type: 'basic',
        link: '/dashboard/integration-syn-options',
        // icon: 'heroicons_outline:desktop-computer',
      },
    ],
  },
  {
    id: 'sync-logs.orders',
    title: 'Orders',
    type: 'basic',
    link: '/orders',
    icon: 'heroicons_outline:document-text',
  },
  {
    id: 'sync-logs.products',
    title: 'Products',
    type: 'basic',
    link: '/products',
    icon: 'heroicons_outline:cube',
  },
  {
    id: 'settings.product-groups',
    title: 'Product Groups',
    type: 'basic',
    link: '/settings/groups',
    icon: 'heroicons_outline:cube-transparent',
  },
  {
    id: 'settings.promotions',
    title: 'Promotions',
    type: 'basic',
    link: '/promotions',
    icon: 'feather:tag',
  },
  //   {
  //     id: 'company.settings',
  //     title: 'Settings',
  //     type: 'basic',
  //     icon: 'heroicons_outline:cog',
  //     link: '/settings/product/attributes',
  //     hidden: item => false,
  //   },
];

//Master user nav items

export const masterUserNavigationItems: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboard',
    type: 'collapsable',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard/channel-status',
    children: [
      {
        id: 'sync-logs.integrationStatus',
        title: 'Channel Status',
        type: 'basic',
        // icon: 'heroicons_outline:chip',
        link: '/dashboard/channel-status',
      },
      {
        id: 'sync-logs.integrationSyncOptions',
        title: 'Sync Options',
        type: 'basic',
        // icon: 'heroicons_outline:desktop-computer',
        link: '/dashboard/integration-syn-options',
      },
    ],
  },
  {
    id: 'sync-logs.orders',
    title: 'Orders',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/orders',
  },
  {
    id: 'sync-logs.products',
    title: 'Products',
    type: 'basic',
    icon: 'heroicons_outline:cube',
    link: '/products',
  },
  {
    id: 'settings.product-groups',
    title: 'Product Groups',
    type: 'basic',
    link: '/groups',
    icon: 'heroicons_outline:cube-transparent',
  },
  {
    id: 'settings.promotions',
    title: 'Promotions',
    type: 'basic',
    link: '/promotions',
    icon: 'feather:tag',
  },
  {
    id: 'settings.instances',
    title: 'Instances',
    type: 'basic',
    icon: 'feather:share-2',
    link: '/instances',
  },
  {
    id: 'connections',
    title: 'Connections',
    type: 'collapsable',
    icon: 'feather:share-2',
    children: [
      {
        id: 'settings.integrations',
        title: 'Channels',
        type: 'basic',
        // icon: 'heroicons_outline:status-online',
        link: '/integrations',
      },
      {
        id: 'settings.sources',
        title: 'Sources',
        // icon: 'heroicons_outline:globe',
        type: 'basic',
        link: '/sources',
      },
    ],
  },
  {
    id: 'sync-events',
    title: 'Sync Events',
    type: 'basic',
    icon: 'heroicons_outline:calendar',
    link: '/sync-events',
    hidden: item => (LocalStorageUtils.companyId ? false : true),
  },
  {
    id: 'company.settings',
    title: 'Settings',
    type: 'basic',
    icon: 'heroicons_outline:cog',
    link: '/settings/product/attributes',
    hidden: item => (LocalStorageUtils.companyId ? false : true),
  },
];
