<div class="flex flex-col flex-0 overflow-hidden">
  <div class="d-flex justify-content-between pb-16px">
    <h2 class="text-2xl font-bold mr-[20px] leading-5 center">{{ title }}</h2>
  </div>
  <div class="overflow-hidden rounded-2xl border border-sm">
    <div
      class="border-b px-4 h-[60px] flex items-center bg-white d-flex justify-content-between">
      <div>
        <div class="flex items-center filter-tabs">
          <div
            class="tab"
            *ngFor="let item of tabOptions"
            [ngClass]="{ 'is-selected': selectedTab.status === item.status }"
            (click)="onTabClick(item)">
            {{ item.name }}
          </div>
          <div class="ml-4" *ngIf="selection.selected?.length > 0">
            <span class="badge badge-primary normal-case mr-[5px]">
              {{ selection.selected.length }} selected
              <span
                class="underline pl-2 cursor-pointer"
                (click)="toggleAllRows()"
                >Unselect all</span
              >
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex gap-2">
          <div class="w-full flex items-center">
            <button
              mat-flat-button
              color="primary"
              class="text-[13px]"
              (click)="onAddClick()">
              <span class="">{{ addButtonText }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Attribute Table -->
    <div class="settings-table-container flex-1 overflow-auto">
      <table
        mat-table
        #table
        [dataSource]="dataSource"
        cdkDropList
        (cdkDropListDropped)="onTableRowDrop($event)"
        class="table-auto mat-elevation-z0 w-full">
        <ng-container matColumnDef="select" sticky>
          <th
            mat-header-cell
            *matHeaderCellDef
            class="min-w-[50px] w-[50px] text-center">
            <mat-checkbox
              color="primary"
              (change)="toggleAllRows()"
              [checked]="allSelectedState"
              [indeterminate]="selection.hasValue() && !allSelectedState">
            </mat-checkbox>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="min-w-[50px] w-[50px] text-center">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="toggleRowSelection(element)"
              [checked]="selection.isSelected(element)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
          <td mat-cell *matCellDef="let element">
            <div [ngSwitch]="column.type">
              <!-- ID with click option -->
              <div
                *ngSwitchCase="'clickableId'"
                class="flex items-center justify-between relative pr-[40px] max-w-[500px]">
                <span
                  class="truncate max-w-full eco-clickable"
                  (click)="onRowAction(element, 'configure')">
                  {{ getNestedProperty(element, column.field) || '-' }}
                </span>
              </div>

              <!-- Regular text field -->
              <div
                *ngSwitchCase="'text'"
                class="flex items-center justify-between relative pr-[40px] max-w-[500px]">
                <span class="truncate max-w-full">
                  {{ getNestedProperty(element, column.field) || '-' }}
                </span>
              </div>

              <!-- Platform with icon -->
              <div
                *ngSwitchCase="'platformWithIcon'"
                class="flex items-center relative pr-[40px] max-w-[500px]">
                <img
                  class="platform-icon"
                  [src]="getNestedProperty(element, column.iconField)" />
                <span class="pl-4 truncate max-w-full">
                  {{ getNestedProperty(element, column.field) || '-' }}
                </span>
              </div>

              <!-- Status badge -->
              <div *ngSwitchCase="'statusBadge'" class="sm:block truncate">
                <span
                  class="badge"
                  [ngClass]="{
                    'badge-success':
                      getNestedProperty(element, column.field) === true,
                    'badge-cancelled':
                      getNestedProperty(element, column.field) === false
                  }">
                  {{
                    getNestedProperty(element, column.field)
                      ? 'ACTIVE'
                      : 'INACTIVE'
                  }}
                </span>
              </div>

              <!-- Date field -->
              <div *ngSwitchCase="'date'" class="text-[13px]">
                <div>
                  {{
                    getNestedProperty(element, column.field)
                      ? (getNestedProperty(element, column.field)
                        | date: 'd MMM y, h:mm a')
                      : (column.defaultDate | date: 'd MMM y, h:mm a')
                  }}
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="capitalize border-b font-medium text-right">
            Action
          </th>
          <td mat-cell *matCellDef="let element" class="text-right">
            <button mat-icon-button [matMenuTriggerFor]="actionMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <button
                *ngFor="let action of actions"
                mat-menu-item
                (click)="onRowAction(element, action.action)">
                {{ action.label }}
              </button>
              <hr *ngIf="actions.length && secondaryActions.length" />
              <button
                *ngFor="let action of secondaryActions"
                mat-menu-item
                (click)="onRowAction(element, action.action)">
                {{ action.label }}
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="light capitalize border-b font-medium"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="draggable-row"></tr>
      </table>

      <!-- No data message -->
      <div
        *ngIf="!dataSource.data.length"
        class="py-8 flex flex-col items-center justify-center text-center">
        <mat-icon class="text-gray-400 mb-2" style="transform: scale(1.5)"
          >info</mat-icon
        >
        <div class="text-lg font-medium text-gray-500">No data available</div>
        <div class="text-sm text-gray-400 mt-1">{{ noDataMessage }}</div>
      </div>
    </div>
  </div>
</div>
